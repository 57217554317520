<template>
  <div class="skeleton-organization">
    <v-list-item class="pr-2">
      <v-list-item-content>
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-list-item-content>
    </v-list-item>
    <div>
      <v-skeleton-loader type="list-item-avatar-two-line" ></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar-two-line" ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrganizationSkeleton"
}
</script>